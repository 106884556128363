<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("target.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-2 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">{{ $t("target.title") }}</h6>
            </b-col>
            <b-col cols="6" class="text-right">
              <button
                class="btn btn-success btn-add ml-auto mr-4"
                @click="onAdd"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("target.add") }}
              </button>
            </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('target.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="focus"
              >
                <b-tabs content-class="mt-2">
                  <b-tab title="Nama SP" active>
                    <div class="input-group">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text" id="basic-addon1">
                          <div class="font-12">
                            <i class="ri-search-line ri-lg ri-sub"></i>
                          </div>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control search-input"
                        v-model="searchSP"
                      />
                    </div>
                    <hr class="m-2" />
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="sp in dataSPSearch"
                        :key="sp.id"
                      >
                        <input
                          :id="sp.id"
                          class="form-radio-item"
                          type="radio"
                          name="kegiatan-label"
                          :value="sp.id"
                          v-model="filterSP"
                        />
                        <label class="form-radio-label ssp-14-400" :for="sp.id">
                          {{ sp.name }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterSP == '' }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="Produk">
                    <div class="input-group">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text" id="basic-addon1">
                          <div class="font-12">
                            <i class="ri-search-line ri-lg ri-sub"></i>
                          </div>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control search-input"
                        v-model="searchProduk"
                      />
                    </div>
                    <hr class="m-2" />
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="prod in dataProdSearch"
                        :key="prod.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :value="prod.id"
                          v-model="filterProduk"
                        />
                        <label class="form-checkbox-label ssp-14-400">
                          {{ prod.brand }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterProduk == '' }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div>
          </div>
          <div class="table-borderless mx-3 table-responsive">
            <b-table-simple hover responsive>
              <b-thead class="bv-head">
                <b-tr>
                  <b-th
                    ><input
                      type="checkbox"
                      @click="selectAll"
                      v-model="allSelected"
                  /></b-th>
                  <b-th v-for="hd in $t('target.headers')" :key="hd.id">
                    {{ hd }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="this.dataSetTarget.length !== 0">
                <b-tr
                  class="ssp-16-400"
                  v-for="data in dataSetTarget"
                  :key="data.id"
                >
                  <b-td
                    ><input
                      type="checkbox"
                      :value="data.id"
                      v-model="targetIds"
                      @click="select"
                  /></b-td>
                  <b-td style="width: 15% !important;">{{
                    data.tos.name
                  }}</b-td>
                  <b-td>
                    <span>{{ data.provs.province }}</span>
                  </b-td>
                  <b-td>{{ data.produknames.brand }}</b-td>
                  <b-td>{{ data.target_quantity }}</b-td>
                  <b-td>{{ data.target_value | formatRp }}</b-td>

                  <b-td>{{ `${data.year} - ${parseInt(data.year) + 1}` }}</b-td>
                  <b-td class="action-column">
                    <div class="action-wrapper font-12">
                      <i
                        class="ri-eye-fill ri-lg ri-mid action-btn"
                        @click="onDetail(data)"
                      ></i>
                      <!-- <i
                        class="ri-edit-fill ri-lg ri-mid action-btn"
                        @click="onEdit(data)"
                      ></i> -->
                      <!-- <i class="ri-delete-back-2-fill ri-lg ri-mid action-btn" @click="onDelete(data.id)"></i> -->
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else class="text-center text-italic">
                <b-tr>
                  <b-td colspan="9">{{ $t("global.empty") }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-target" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">
          <img
            class="mr-2"
            src="@/assets/img/tbhdata-form.png"
            alt="FORM"
            style="height: 1.8rem;"
          />
          {{ modalTitle }}
        </h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <div
          class="mt-0 mb-3 ssp-16"
          style="color: #C4C4C4"
          v-if="!isDetail && !isEdit"
        >
          {{ $t("target.label.note1sp") }}
        </div>
        <form @submit="onSubmit" v-if="!isDetail && !isEdit">
          <div class="form-group">
            <label class="ssp-16 text-black"
              >{{ $t("target.label.sp") }}
              <span v-if="!isDetail" class="text-red">*</span></label
            >
            <custom-select
              v-if="!isDetail"
              :placeholder="$t('target.placeholder.sp')"
              :class="{
                'invalid-form rounded-input': $v.formTambah.id_sp.$error
              }"
              v-model="$v.formTambah.id_sp.$model"
              :options="dataSP"
              label="name"
              :allow-empty="false"
              :disabled="isDisabled"
              @open="resetFormUpload"
            >
            </custom-select>
          </div>
          <section v-for="det in formTambah.id_sp.detail" :key="det.id">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="ssp-16 text-black">
                    {{ $t("sidebar.country") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="det.countrys.country"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <div class="form-group">
                    <label class="ssp-16 text-black">
                      {{ $t("sidebar.region") }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="det.regions.region"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="ssp-16 text-black">
                    {{ $t("sidebar.province") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="det.province.province"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <div class="form-group">
                    <label class="ssp-16 text-black">
                      {{ $t("sidebar.regency") }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="det.kabupaten.kabupaten"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-1" />
          </section>
          <hr class="my-0 py-0" />
          <div class="row">
            <div class="col-12 float-left">
              <div class="mt-2 ssp-16" style="color: #C4C4C4">
                {{ $t("target.label.note2sp") }}
              </div>
            </div>
          </div>
          <div v-for="(form, index) in targetForm" :key="form.id">
            <div class="row mt-3">
              <div class="col-lg-12">
                <div class="form-group">
                  <label class="ssp-16 text-black"
                    >{{ $t("target.label.product") }}
                    <span v-if="!isDetail" class="text-red">*</span>
                  </label>
                  <!-- {{ $v.targetForm.$each[index].$model }} -->
                  <custom-select
                    v-if="!isDetail"
                    :placeholder="$t('liq_reporting.placeholder.product')"
                    v-model="form.produk"
                    required
                    :class="{
                      'invalid-form rounded-input':
                        $v.targetForm.$each[index].produk.$error
                    }"
                    :options="dataProduk"
                    label="brand"
                    :allow-empty="true"
                    @open="resetQuantity(index)"
                  >
                  </custom-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="typo__label ssp-16 text-black"
                    >{{ $t("target.label.qty") }}
                    <span v-if="!isDetail" class="text-red">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="ll-quantity"
                    v-model="form.quantity"
                    :class="{
                      'invalid-form rounded-input':
                        $v.targetForm.$each[index].quantity.$error
                    }"
                    :placeholder="$t('target.placeholder.qty')"
                    :disabled="form.produk === ''"
                    @input="setPrice(index)"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="typo__label ssp-16 text-black"
                    >{{ $t("target.label.value") }}
                    <span v-if="!isDetail" class="text-red">*</span></label
                  >
                  <vue-numeric
                    currency="Rp"
                    separator="."
                    class="form-control"
                    v-model="form.values"
                    :disabled="true"
                  />
                  <!-- <input
                    type="text"
                    class="form-control"
                    id="ll-value"
                    v-model="form.values"
                    placeholder="Rp "
                    :disabled="true"
                  /> -->
                </div>
              </div>
            </div>
            <section v-if="form.produk">
              <span
                v-b-toggle="'collapse-' + index"
                class="btn btn-orange"
                style="font-size: 13px"
                @click="breakdown(index)"
              >
                {{ $t("target.label.breakdown") }}
              </span>
              <span
                class="btn btn-secondary ml-1"
                @click="removeTarget(index)"
                style="font-size: 13px; box-shadow: none !important;"
              >
                &#10006;
              </span>
            </section>
            <b-collapse :id="'collapse-' + index" class="mt-3">
              <div class="row">
                <div class="col-12 float-left">
                  <div class="my-2 br-month">
                    {{ $t("target.label.note3") }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="typo__label ssp-16 text-black">{{
                      $t("target.label.sum")
                    }}</label>
                    <vue-numeric
                      currency="Rp"
                      separator="."
                      class="form-control"
                      v-model="form.values"
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="typo__label ssp-16 text-black">{{
                      $t("target.label.rem")
                    }}</label>
                    <vue-numeric
                      currency="Rp"
                      separator="."
                      v-model="form.sisa"
                      :disabled="true"
                      type="text"
                      class="form-control"
                      :class="{
                        'invalid-form rounded-input': form.conditionSisa
                      }"
                    ></vue-numeric>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  v-if="form.breakDown.length > 0"
                  class="collapse"
                  :class="{ show: form.expand }"
                >
                  <div
                    v-for="(subItem, j) in form.breakDown"
                    :key="j"
                    class="row mx-1"
                  >
                    <div class="col-sm-3 pr-1" v-if="j !== 12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="ll-month"
                          v-model="subItem.month"
                          readonly
                          :disabled="isDisabled"
                        />
                      </div>
                    </div>
                    <div class="col-sm-4 pl-2 pr-0" v-if="j !== 12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="ll-qua"
                          v-model="subItem.quantity"
                          :placeholder="$t('target.placeholder.qty')"
                          :disabled="isDisabled"
                          @input="setPriceBreakdown(index, j)"
                        />
                      </div>
                    </div>
                    <div class="col-sm-5" v-if="j !== 12">
                      <div class="form-group">
                        <vue-numeric
                          currency="Rp"
                          separator="."
                          class="form-control"
                          v-model="subItem.values"
                          :disabled="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <a
                class="btn btn-success ssp-14 w-100 my-3 py-2"
                @click="addTarget"
              >
                {{ $t("target.label.add") }}
              </a>
            </div>
            <div class="col-lg-8">
              <button
                v-if="!isDetail"
                type="submit"
                class="btn btn-primary ssp-14 w-100 my-3 py-2"
              >
                {{ $t("target.label.save") }}
              </button>
            </div>
          </div>
        </form>
        <form @submit="onUpdateSubmit" v-else-if="isDetail || isEdit">
          <div class="row">
            <div class="col-6 float-left">
              <div class="my-3">{{ $t("target.label.note2sp") }}</div>
            </div>
          </div>
          <table class="table table-borderless">
            <tr class="target-detail">
              <td class="ssp-16 text-black">{{ $t("target.label.for") }}</td>
              <td>:</td>
              <td class="ssp-16-400 text-black">{{ formDetail.rm }}</td>
            </tr>
            <tr class="target-detail">
              <td class="ssp-16 text-black">
                {{ $t("target.label.product") }}
              </td>
              <td>:</td>
              <td class="ssp-16-400 text-black">{{ formDetail.produk }}</td>
            </tr>
            <tr class="target-detail">
              <td class="ssp-16 text-black">{{ $t("target.label.qty") }}</td>
              <td>:</td>
              <td class="ssp-16-400 text-black">{{ formDetail.quantity }}</td>
            </tr>
            <tr class="target-detail">
              <td class="ssp-16 text-black">{{ $t("target.label.value") }}</td>
              <td>:</td>
              <td class="ssp-16-400 text-black">
                {{ formDetail.value | formatRp }}
              </td>
            </tr>
            <tr class="target-detail">
              <td class="ssp-16 text-black">{{ $t("target.label.year") }}</td>
              <td>:</td>
              <td class="ssp-16-400 text-black">
                {{ `${formDetail.tahun} - ${parseInt(formDetail.tahun) + 1}` }}
              </td>
            </tr>
          </table>

          <div class="row">
            <div class="col-6 float-left">
              <div class="mb-3 mt-0">{{ $t("target.label.note3") }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-3 pr-1">
              <div class="form-group mb-1">
                <label class="ssp-16 text-black">Bulan</label>
              </div>
            </div>
            <!-- <div class="col-3 px-1">
              <div class="form-group mb-1">
                <label class="ssp-16 text-black">Produk</label>
              </div>
            </div> -->
            <div class="col-3 px-1">
              <div class="form-group mb-1">
                <label class="ssp-16 text-black">Quantity</label>
              </div>
            </div>
            <div class="col-6 pl-1">
              <div class="form-group mb-1">
                <label class="ssp-16 text-black">Value</label>
              </div>
            </div>
          </div>
          <div
            class="row"
            v-for="(detail, index) in formDetail.monthly"
            :key="detail.id"
          >
            <div class="col-3 pr-1">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="detail.month"
                  :disabled="true"
                />
              </div>
            </div>
            <!-- <div class="col-3 px-1">
              <div class="form-group">
                <input type="text" class="form-control" v-model="detail.produknames['brand']" :disabled="isDisabled" />
              </div>
            </div> -->
            <div class="col-3 px-1">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="detail.target_quantity"
                  :disabled="isDisabled"
                  :placeholder="$t('target.placeholder.qty')"
                  @input="setMonthlyValue(index)"
                />
              </div>
            </div>
            <div class="col-6 pl-1">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="detail.target_value"
                  :disabled="true"
                  placeholder="Value"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 pl-1">
              <button
                v-if="!isDetail"
                type="submit"
                class="btn btn-primary ssp-14 w-100 my-3 py-2"
              >
                {{ $t("target.label.save") }}
              </button>
            </div>
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VueNumeric from "vue-numeric";
import { Card } from "@/components/index";
import Multiselect from "vue-multiselect";

import { required } from "vuelidate/lib/validators";
import JwtService from "@/core/services/jwt.service";
import { RoleType, SalesTargetType } from "@/graphql/enum.type.js";
import { Q_LIST_TARGET, M_ADD_TARGET, M_EDIT_TARGET } from "@/graphql/tss";
import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import {
  Q_LIST_USER,
  Q_DETAIL_USER,
  Q_LIST_PRODUK,
  Q_LIST_USERSEARCH
} from "@/graphql/queries";
// import * as XLSX from "xlsx";

const customDecimal = value => /^\d*\.?\d*$/.test(value);

export default {
  components: {
    Card,
    VueNumeric,
    "custom-select": Multiselect
  },
  data() {
    return {
      isLoading: false,
      totalPages: null,
      totalData: null,
      currPage: 1,
      perPage: 10,

      dataSetTarget: [],
      dataProduk: [],
      dataSP: [],
      dataSPSearch: [],
      dataProdSearch: [],

      detailUser: JwtService.getDetail(),
      detailSP: {
        country: "",
        region: "",
        province: "",
        district: ""
      },
      countryID: [],
      regionID: [],
      provID: [],
      kabID: [],

      optionRole: [RoleType.RM, RoleType.SP],
      optionTarget: [SalesTargetType.ANNUAL, SalesTargetType.MONTHLY],
      modalTitle: null,
      isDisabled: null,
      isDetail: null,
      isEdit: false,
      isHide: true,
      filterInput: "",
      filterProduk: [],
      filterSP: "",
      searchProduk: "",
      searchSP: "",

      formTambah: {
        id_sp: ""
      },

      targetForm: [
        {
          produk: "",
          quantity: null,
          values: 0,
          remaining: 0,
          expand: false,
          breakDown: []
        }
      ],
      sisa: 0,
      conditionSisa: false,

      formUpload: [],

      formDetail: {
        rm: "",
        id_sp: "",
        produk: "",
        id_produk: "",
        quantity: "",
        value: "",
        tahun: "",
        monthly: []
      },

      targetIds: [],
      selected: [],
      allSelected: false
    };
  },
  validations: {
    formTambah: {
      id_sp: { required }
    },
    targetForm: {
      $each: {
        produk: { required },
        quantity: { required, customDecimal }
      }
    }
  },
  apollo: {
    listTarget: {
      query: () => Q_LIST_TARGET,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          from: JwtService.getID(),
          to: this.filterSP,
          tahun: "",
          bulan: "",
          country_id: this.getCountryID(),
          region_id: this.getRegionID(),
          prov: this.getProvinceID(),
          kab: [],
          kec: [],
          kel: [],
          produk: this.filterProduk,
          types: this.optionTarget[0],
          orderBy: "CREATED_AT",
          sortBy: "DESC"
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataSetTarget = data.listTarget.targets;
        this.totalPages = data.listTarget.totalPage;
        this.totalData = data.listTarget.total;
      },
      error(err) {
        this.catchError(err);
      }
    },
    listUser: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: this.searchSP,
          role: this.optionRole[1],
          country_id: [],
          region_id: [],
          province_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [JwtService.getID()],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      result({ data }) {
        this.dataSPSearch = data.listUser.users;
      }
    },
    listProduk: {
      query: () => Q_LIST_PRODUK,
      variables() {
        return {
          page: 1,
          limit: 99999,
          keyword: this.searchProduk
        };
      },
      result({ data }) {
        this.dataProdSearch = data.listProduk.produks;
      },
      error(e) {
        this.catchError(e);
      }
    }
  },
  methods: {
    // setDetails() {
    //   console.log(this.formTambah.id_sp.detail);
    //   // if (this.formTambah.id_sp.detail[0].countrys !== null) {
    //   //   this.detailSP.country = this.formTambah.id_sp.detail[0].countrys.country;
    //   // }
    //   // if (this.formTambah.id_sp.detail[0].regions !== null) {
    //   //   this.detailSP.region = this.formTambah.id_sp.detail[0].regions.region;
    //   // }
    //   // if (this.formTambah.id_sp.detail[0].province !== null) {
    //   //   this.detailSP.province = this.formTambah.id_sp.detail[0].province.province;
    //   // }
    //   // if (this.formTambah.id_sp.detail[0].kabupaten !== null) {
    //   //   this.detailSP.district = this.formTambah.id_sp.detail[0].kabupaten.kabupaten;
    //   // }
    // },
    resetFilter() {
      // this.filterSelected1 = null;
      this.filterSP = "";
      this.filterProduk = [];
    },

    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },

    onAdd() {
      this.modalTitle = this.$t("target.add");
      this.isDisabled = false;
      this.isDetail = false;
      this.isEdit = false;
      this.isHide = true;
      this.$bvModal.show("modal-target");
      this.resetForm();
    },
    async onDetail(data) {
      this.modalTitle = this.$t("target.detail");
      this.isDisabled = true;
      this.isDetail = true;
      this.isEdit = false;
      this.$bvModal.show("modal-target");

      this.resetForm();
      this.formDetail.rm = data.tos["name"];
      this.formDetail.produk = data.produknames["brand"];
      this.formDetail.quantity = data.target_quantity;
      this.formDetail.value = data.target_value;
      this.formDetail.tahun = data.year;
      this.formDetail.monthly = [];

      return new Promise((resolve, reject) => {
        const listDetailTarget = this.$apollo.query({
          query: Q_LIST_TARGET,
          variables: {
            page: this.currPage,
            limit: 99,
            keyword: this.filterInput,
            from: JwtService.getID(),
            to: data.tos["id"],
            tahun: "",
            bulan: "",
            country_id: this.getCountryID(),
            region_id: this.getRegionID(),
            prov: this.getProvinceID(),
            kab: [],
            kec: [],
            kel: [],
            produk: [data.produknames["id"]],
            types: this.optionTarget[1],
            orderBy: "CREATED_AT",
            sortBy: "ASC"
          }
        });
        if (resolve) {
          resolve(listDetailTarget);
        } else {
          reject(listDetailTarget);
        }
      })
        .then(response => {
          this.formDetail.monthly = response.data.listTarget.targets;
        })
        .catch(err => {
          this.$swal.fire({
            icon: "error",
            title: "Error!",
            text: err.message,
            width: 400,
            confirmButtonText: "OK"
          });
        });
    },
    setMonthlyValue(index) {
      this.formDetail.monthly[index].target_value =
        this.formDetail.monthly[index].produknames.price *
        this.formDetail.monthly[index].target_quantity;
    },
    async onEdit(data) {
      this.modalTitle = "Ubah Target";
      this.isDisabled = false;
      this.isDetail = false;
      this.isEdit = true;
      this.$bvModal.show("modal-target");

      this.resetForm();
      this.formDetail.rm = data.tos["name"];
      this.formDetail.id_sp = data.tos["id"];
      this.formDetail.produk = data.produknames["brand"];
      this.formDetail.id_produk = data.produknames["id"];
      this.formDetail.quantity = data.target_quantity;
      this.formDetail.value = data.target_value;
      this.formDetail.tahun = data.year;
      this.formDetail.monthly = [];

      return new Promise((resolve, reject) => {
        const listDetailTarget = this.$apollo.query({
          query: Q_LIST_TARGET,
          variables: {
            page: 1,
            limit: 99,
            keyword: "",
            from: JwtService.getID(),
            to: data.tos["id"],
            tahun: data.year,
            bulan: "",
            country_id: this.getCountryID(),
            region_id: this.getRegionID(),
            prov: [],
            kab: [],
            kec: [],
            kel: [],
            produk: [data.produknames["id"]],
            types: this.optionTarget[1]
          }
        });
        if (resolve) {
          resolve(listDetailTarget);
        } else {
          reject(listDetailTarget);
        }
      })
        .then(response => {
          this.formDetail.monthly = response.data.listTarget.targets;
          // console.log(this.formDetail.monthly);
        })
        .catch(err => {
          this.$swal.fire({
            icon: "error",
            title: "Error!",
            text: err.message,
            width: 400,
            confirmButtonText: "OK"
          });
        });
    },
    updatePromise(index, data) {
      return new Promise((resolve, reject) => {
        const updateTarget = this.$apollo.mutate({
          mutation: M_EDIT_TARGET,
          variables: {
            id: data.monthly[index].id,
            from: JwtService.getID(),
            to: data.id_sp,
            month: data.monthly[index].month,
            year: data.tahun,
            country: parseInt(data.monthly[index].countrys.id),
            region: parseInt(data.monthly[index].regions.id),
            produk: data.id_produk,
            qty: parseFloat(data.monthly[index].target_quantity),
            value: data.monthly[index].target_value.toString(),
            tipe: this.optionTarget[1]
          }
        });
        if (resolve) {
          resolve(updateTarget);
        } else {
          reject(updateTarget);
        }
      })
        .then(response => {
          this.$apollo.queries.listTarget.refetch();
          if (response) {
            this.closeModal();
            return response;
          }
        })
        .catch(err => {
          this.$swal.fire({
            icon: "error",
            title: "Error!",
            text: err.message,
            width: 400,
            confirmButtonText: "OK"
          });
        });
    },
    editData(data) {
      return new Promise((resolve, reject) => {
        const updateTarget = this.$apollo.mutate({
          mutation: M_EDIT_TARGET,
          variables: {
            id: data.id,
            to: data.tos.id,
            from: JwtService.getID(),
            price: data.produknames.price,
            month: data.month,
            year: data.year,
            region: parseInt(data.regions.id),
            country: parseInt(data.countrys.id),
            produk: data.produknames.id,
            qty: parseFloat(data.target_quantity),
            value: data.target_value.toString(),
            tipe: this.optionTarget[1]
          }
        });
        if (resolve) {
          resolve(updateTarget);
        } else {
          reject(updateTarget);
        }
      })
        .then(response => {
          if (response) {
            return response;
          }
        })
        .catch(err => {
          this.$swal.fire({
            icon: "error",
            title: "Error!",
            text: err.message,
            width: 400,
            confirmButtonText: "OK"
          });
        });
    },
    onUpdateSubmit(evt) {
      evt.preventDefault();
      for (let i = 0; i < this.formDetail.monthly.length; i++) {
        const result = this.editData(this.formDetail.monthly[i]);
        if (result) {
          if (i === this.formDetail.monthly.length - 1) {
            this.$apollo.queries.listTarget.refetch();
            this.toastAlert(this.$t("alert.edit"));
            this.closeModal();
            window.location.reload();
          }
        } else {
          this.$swal.fire({
            icon: "error",
            title: "Error!",
            text: "Kesalahan dalam menyimpan data",
            width: 400,
            confirmButtonText: "OK"
          });
        }
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-target");
      this.resetForm();
      this.resetFormUpload();
      this.resetTargetForm();
    },
    resetForm() {
      this.formTambah.id_sp = "";
      this.detailSP = {
        country: "",
        region: "",
        province: "",
        district: ""
      };
      this.formDetail.rm = "";
      this.formDetail.produk = "";
      this.formDetail.quantity = "";
      this.formDetail.value = "";
      this.formDetail.tahun = "";
      this.formDetail.monthly = [];

      this.targetForm = [
        {
          produk: "",
          quantity: null,
          values: 0,
          remaining: 0,
          expand: false,
          breakDown: []
        }
      ];

      (this.formDetail = {
        rm: "",
        produk: "",
        quantity: "",
        value: "",
        tahun: "",
        monthly: []
      }),
        this.$v.$reset();
    },
    selectAll() {
      this.targetIds = [];
      if (!this.allSelected) {
        for (let i in this.dataSetTarget) {
          this.targetIds.push(this.dataSetTarget[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    ConvertToRole(data) {
      let Value;
      switch (data) {
        case "SUPER_ADMIN":
          Value = "SUPER ADMIN";
          break;
        case "NMM":
          Value = "Marketing Head of Indonesia";
          break;
        case "NSM":
          Value = "National Sales Manager";
          break;
        case "SA":
          Value = "Sales Admin";
          break;
        case "RM":
          Value = "Regional Manager";
          break;
        case "AM":
          Value = "Areal Manager";
          break;
        case "TSS":
          Value = "Territory Sales Supervisor";
          break;
        case "SP":
          Value = "Sales Promotor";
          break;
        default:
          Value = "Role Invalid";
          break;
      }
      return Value;
    },
    ConverToKegiatan(data) {
      let Value;
      switch (data) {
        case "VD":
          Value = "Visit Distributor";
          break;
        case "VR1":
          Value = "Visit Retailer 1";
          break;
        case "VR2":
          Value = "Visit Retailer 2";
          break;
        case "VF":
          Value = "Visit Farmer";
          break;
        case "VK":
          Value = "Visit Kebun";
          break;
        case "MA":
          Value = "Marketing Activity";
          break;
        case "ADM":
          Value = "Administration";
          break;
        default:
          Value = "Wrong Activity";
          break;
      }
      return Value;
    },
    addTarget() {
      this.targetForm.push({
        produk: "",
        quantity: null,
        values: 0,
        remaining: 0,
        expand: false,
        breakDown: []
      });
    },
    removeTarget(index) {
      this.targetForm.splice(index, 1);
    },
    async onSubmit(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$error) {
        return new Promise((resolve, reject) => {
          const result = this.$apollo.query({
            query: Q_DETAIL_USER,
            variables: {
              id: this.formTambah.id_sp["id"]
            }
          });
          if (resolve) {
            resolve(result);
          } else {
            reject(result);
          }
        })
          .then(async response => {
            let valid = true;
            for (let j = 0; j < this.targetForm.length; j++) {
              if (this.targetForm[j].breakDown.length === 0) valid = false;
              if (this.targetForm[j].breakDown.length > 0) {
                for (let k = 0; k < this.targetForm[j].breakDown.length; k++) {
                  this.formUpload.push({
                    country_id: parseInt(
                      response.data.detailUser.detail[0].country_id
                    ),
                    region_id: parseInt(
                      response.data.detailUser.detail[0].region_id
                    ),
                    prov_id: response.data.detailUser.detail[0].province_id,
                    kab_id: response.data.detailUser.detail[0].kabupaten_id,
                    produk_id: this.targetForm[j].breakDown[k].id_produk,
                    price: parseInt(this.targetForm[j].breakDown[k].price),
                    target_value: this.targetForm[j].breakDown[
                      k
                    ].values.toString(),
                    target_quantity: parseFloat(
                      this.targetForm[j].breakDown[k].quantity
                    ),
                    tipe_target: this.targetForm[j].breakDown[k].type,
                    year: this.targetForm[j].breakDown[k].tahun,
                    month: this.targetForm[j].breakDown[k].month
                  });
                }
              } else {
                this.formUpload.push({
                  country_id: parseInt(
                    response.data.detailUser.detail[0].country_id
                  ),
                  region_id: parseInt(
                    response.data.detailUser.detail[0].region_id
                  ),
                  prov_id: response.data.detailUser.detail[0].province_id,
                  kab_id: response.data.detailUser.detail[0].kabupaten_id,
                  produk_id: this.targetForm[j].produk.id,
                  price: parseInt(this.targetForm[j].produk.price),
                  target_value: this.targetForm[j].values.toString(),
                  target_quantity: parseFloat(this.targetForm[j].quantity),
                  year: new Date().getFullYear().toString(),
                  tipe_target: this.optionTarget[0]
                });
              }
            }
            if (!valid) {
              this.$swal.fire({
                icon: "error",
                title: "Error!",
                text: "Target belum di breakdown!",
                width: 400,
                confirmButtonText: "OK"
              });
              return;
            }
            return new Promise((resolve, reject) => {
              const addTarget = this.$apollo.mutate({
                mutation: M_ADD_TARGET,
                variables: {
                  from: JwtService.getID(),
                  to: this.formTambah.id_sp.id,
                  produk: this.formUpload
                }
              });
              if (resolve) {
                resolve(addTarget);
              } else {
                reject(addTarget);
              }
            })
              .then(response => {
                this.$apollo.queries.listTarget.refetch();
                if (response) {
                  this.toastAlert(this.$t("alert.add"));
                  this.closeModal();
                }
              })
              .catch(err => {
                this.errorAlert(err);
              });
          })
          .catch(err => {
            this.$swal.fire({
              icon: "error",
              title: "Error!",
              text: err,
              width: 400,
              confirmButtonText: "OK"
            });
          });
      }
    },
    setPrice(data) {
      this.targetForm[data].values =
        this.targetForm[data].produk["price"] * this.targetForm[data].quantity;
    },
    setPriceBreakdown(i, j) {
      const velue =
        this.targetForm[i].breakDown[j].price *
        this.targetForm[i].breakDown[j].quantity;
      this.targetForm[i].breakDown[j].values = velue.toString();
      let items = this.targetForm[i].breakDown.slice(0, 12);
      let countAllMonth = items.reduce((val, element) => {
        return val + parseInt(element.values);
      }, 0);
      let SUSUK = countAllMonth - parseInt(this.targetForm[i].values);
      this.targetForm[i].sisa = Math.abs(SUSUK);
      if (countAllMonth !== this.targetForm[i].values) {
        this.targetForm[i].conditionSisa = true;
      } else if (countAllMonth === this.targetForm[i].values) {
        this.targetForm[i].conditionSisa = false;
      }
    },
    resetQuantity(data) {
      this.targetForm[data].quantity = null;
      this.targetForm[data].produk = "";
      this.targetForm[data].values = 0;
      for (let j = 0; j <= 12; j++) {
        this.targetForm[data].breakDown.pop();
      }
    },
    resetTargetForm() {
      this.targetForm[0].quantity = null;
      this.targetForm[0].produk = "";
      this.targetForm[0].values = 0;
      this.targetForm[0].expand = false;
      for (let j = 0; j <= 12; j++) {
        this.targetForm[0].breakDown.pop();
      }
    },
    resetFormUpload() {
      this.formUpload = [];
    },
    // closeBRK() {
    //   this.isHide = true
    // },
    breakdown(data) {
      this.isHide = !this.isHide;
      if (this.targetForm[data].produk.length !== 0) {
        const QtyPerbulan = Math.round(this.targetForm[data].quantity / 12);
        if (this.targetForm[data].breakDown.length !== 13) {
          for (let i = 0; i < 12; i++) {
            let bulan;
            let currentMonth = (new Date().getMonth() + i) % 12;
            let currentYear = new Date().getFullYear();
            let targetYear = currentYear;

            if (currentMonth > 8) {
              targetYear = currentYear;
            } else {
              targetYear = currentYear - 1;
            }

            switch (currentMonth) {
              case 0:
                bulan = "April";
                break;
              case 1:
                bulan = "Mei";
                break;
              case 2:
                bulan = "Juni";
                break;
              case 3:
                bulan = "Juli";
                break;
              case 4:
                bulan = "Agustus";
                break;
              case 5:
                bulan = "September";
                break;
              case 6:
                bulan = "Oktober";
                break;
              case 7:
                bulan = "November";
                break;
              case 8:
                bulan = "Desember";
                break;
              case 9:
                bulan = "Januari";
                break;
              case 10:
                bulan = "Februari";
                break;
              case 11:
                bulan = "Maret";
                break;
              default:
                bulan = null;
                break;
            }

            this.targetForm[data].breakDown.push({
              id_produk: this.targetForm[data].produk["id"],
              produk: this.targetForm[data].produk["brand"],
              price: this.targetForm[data].produk["price"],
              quantity: QtyPerbulan,
              values: this.targetForm[data].produk["price"] * QtyPerbulan,
              month: bulan,
              tahun: targetYear.toString(),
              type: this.optionTarget[1]
            });
          }
          this.targetForm[data].breakDown.push({
            id_produk: this.targetForm[data].produk["id"],
            produk: this.targetForm[data].produk["brand"],
            price: this.targetForm[data].produk["price"],
            quantity: this.targetForm[data].quantity,
            values: this.targetForm[data].values.toString(),
            month: null,
            tahun:
              new Date().getMonth() >= 3
                ? new Date().getFullYear().toString()
                : (new Date().getFullYear() - 1).toString(),
            type: this.optionTarget[0]
          });
          this.targetForm = this.targetForm.map((item, i) => {
            item.expand = !item.expand;
            if (i !== data) {
              item.expand = false;
            }
            return item;
          });
        } else {
          this.targetForm = this.targetForm.map((item, i) => {
            item.expand = !item.expand;
            if (i !== data) {
              item.expand = false;
            }
            return item;
          });
        }
        let items = this.targetForm[data].breakDown.slice(0, 12);
        let countAllMonth = items.reduce((val, element) => {
          return val + parseInt(element.values);
        }, 0);
        let SUSUK = countAllMonth - parseInt(this.targetForm[data].values);
        this.targetForm[data].sisa = Math.abs(SUSUK);
        if (countAllMonth !== this.targetForm[data].values) {
          this.targetForm[data].conditionSisa = true;
        } else if (countAllMonth === this.targetForm[data].values) {
          this.targetForm[data].conditionSisa = false;
        }
      }
    },
    async fetchSP() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_USER,
          variables: {
            page: 1,
            limit: 9999,
            keyword: "",
            role: this.optionRole[1],
            country_id: [],
            region_id: [],
            province_id: [],
            kab_id: [],
            kec_id: [],
            kel_id: [],
            tss_id: [JwtService.getID()],
            orderBy: "NAME",
            sortBy: "ASC"
          }
        });
        this.dataSP = result.data.listUser.users;
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    async fetchProduk() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_PRODUK,
          variables: {
            page: 1,
            limit: 99999,
            keyword: ""
          }
        });
        this.dataProduk = result.data.listProduk.produks;
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    //     async handleExport() {
    // this.isLoading = true;
    //   this.perPage = this.totalData;
    //   this.currPage = 1;
    //   setTimeout(() => {
    //     const filter = [];
    //     for (let i = 0; i < this.dataSetTarget.length; i++) {
    //       let from, to, cou, reg, prov, kab, produk;
    //       if (this.dataSetTarget[i].froms != null) {
    //         from = this.dataSetTarget[i].froms.name;
    //       }
    //       if (this.dataSetTarget[i].tos != null) {
    //         to = this.dataSetTarget[i].tos.name;
    //       }
    //       if (this.dataSetTarget[i].countrys != null) {
    //         cou = this.dataSetTarget[i].countrys.country;
    //       }
    //       if (this.dataSetTarget[i].regions != null) {
    //         reg = this.dataSetTarget[i].regions.region;
    //       }
    //       if (this.dataSetTarget[i].provs != null) {
    //         prov = this.dataSetTarget[i].provs.province;
    //       }
    //       if (this.dataSetTarget[i].kabs != null) {
    //         kab = this.dataSetTarget[i].kabs.kabupaten;
    //       }
    //       if (this.dataSetTarget[i].produknames != null) {
    //         produk = this.dataSetTarget[i].produknames.brand;
    //       }
    //       filter.push({
    //         from: from,
    //         year: this.dataSetTarget[i].year,
    //         month: this.dataSetTarget[i].month,
    //         to: to,
    //         country: cou,
    //         region: reg,
    //         prov: prov,
    //         kab: kab,
    //         produk: produk,
    //         target_quantity: this.dataSetTarget[i].target_quantity,
    //         target_value: this.dataSetTarget[i].target_value,
    //         tipe_target: this.dataSetTarget[i].tipe_target
    //       });
    //     }
    //     const data = XLSX.utils.json_to_sheet(filter);
    //     const wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, data, "Target");
    //     XLSX.writeFile(wb, "datatarget.xlsx");
    //     this.perPage = 10;
    //   }, 2000);
    // }
    async handleExport() {
      this.isLoading = true;
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_TARGET,
          variables: {
            page: this.currPage,
            limit: this.perPage,
            keyword: this.filterInput,
            from: JwtService.getID(),
            to: this.filterSP,
            tahun: "",
            bulan: "",
            country_id: this.getCountryID(),
            region_id: this.getRegionID(),
            prov: [],
            kab: [],
            kec: [],
            kel: [],
            produk: this.filterProduk,
            types: this.optionTarget[0]
          }
        });
        const list = result.data.listTarget.targets;
        import("@/plugins/export-excel").then(excel => {
          const tHeader = [
            "Target to",
            "Country",
            "Region",
            "Province",
            "Kabupaten",
            "Month",
            "Produk",
            "Qty",
            "Value",
            "Type",
            "Year"
          ];
          const filterVal = [
            "to",
            "country",
            "region",
            "prov",
            "kab",
            "month",
            "produkname",
            "target_quantity",
            "target_value",
            "tipe_target",
            "year"
          ];
          setTimeout(() => {
            list.map(a => {
              if (a.tos) {
                a.to = a.tos.name;
              }
              if (a.countrys) {
                a.country = a.countrys.country;
              }
              if (a.provs) {
                a.prov = a.provs.province;
              }
              if (a.kabs) {
                a.kab = a.kabs.kabupaten;
              }
              if (a.produknames) {
                a.produkname = a.produknames.brand;
              }
              return a;
            });
            const data = this.formatJson(filterVal, list);
            excel.export_json_to_excel({
              header: tHeader,
              data,
              filename: `settarget-${this.dateFr(
                new Date(),
                "DDMMYYYYHHMMss"
              )}`,
              autoWidth: true,
              bookType: "xlsx"
            });
          }, 1500);
        });
      } catch (e) {
        this.errorAlert(e);
      }
      this.isLoading = false;
    }
  },
  mounted() {
    this.fetchSP();
    this.fetchProduk();
  }
};
</script>

<style>
.search-section {
  padding-left: 0;
}

.input-group-prepend {
  border-right: 0;
}

.search-input {
  border-left: 0 !important;
  border-radius: 8px;
}

.petani-table thead {
  background-color: #fafafa;
  border-radius: 50px;
}

.petani-table tbody tr td {
  /* background-color: red; */
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  vertical-align: middle;
}

.petani-table tbody tr td ul {
  margin-bottom: 0;
}

.target-detail td {
  padding: 0.2rem;
}
</style>
<!-- <style src="@vueform/multiselect/themes/default.css">
</style> -->
